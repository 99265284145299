import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { LocalStorageService } from "../services/local-storage.service";

export const authGuard: CanActivateFn = (route, state) => {
    const authService = inject(LocalStorageService);
    const router = inject(Router);
    const currentUserData = authService.getUserToken();

    // Check if user's token exist
    if (currentUserData === null || currentUserData === undefined) {
        router.navigate(["/management-module/login"]);
        return false;
    }
    return true;
};
