<div class="home_Component">
    <div class="banner-area banner-img-one">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-text">
                        <h1>
                            Find Your <span>Desire</span> Candidates or Jobs
                        </h1>
                        <p>Jobs, Employment & Future Career Opportunities
                            
                        </p>
                        <div class="d-flex justify-content-center">
                            <div class="d-flex align-items-center mb-5">
                              <button class="btn btn-primary btn-lg me-2">
                                Book an appointment
                              </button>
                              <img src="../../../../assets/img/home-1/calendly.png" alt="Calendly Logo" class="img-fluid" style="height: 50px; width:100px ; border-radius: 10px;">
                            </div>
                          </div>
                        <div class="container">
                            <div class="row g-5">
                                <div class="col-lg-6">
                                    <h4
                                        class="form-label text-white text-start mb-4"
                                    >
                                        Looking for Talent?
                                    </h4>
                                    <div
                                        class="banner-form-area"
                                        style="width: 100%"
                                    >
                                        <form>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            placeholder="Search Resumes"
                                                        />
                                                        <!-- <label
                                            ><i class="icofont-search-1"></i
                                        ></label> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <button
                                                type="submit"
                                                routerLink="/candidate-list"
                                                class="btn banner-form-btn"
                                            >
                                                Search
                                            </button>
                                        </form>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <h4
                                        class="form-label text-white text-start mb-4"
                                    >
                                        Looking for Work?
                                    </h4>
                                    <div
                                        class="banner-form-area"
                                        style="width: 100%"
                                    >
                                        <form>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <input
                                                            type="text"
                                                            class="form-control"
                                                            placeholder="Search Jobs"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <button
                                                type="submit"
                                                routerLink="/candidate/job-list"
                                                class="btn banner-form-btn"
                                            >
                                                Search
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="banner-btn">
                            <a routerLink="/create-account"
                                >Create Your Profile</a
                            >
                            <a routerLink="/submit-resume">Upload Your CV</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="account-area">
        <div class="container">
            <div class="row account-wrap">
                <div class="col-sm-6 col-lg-4">
                    <div class="account-item">
                        <i class="flaticon-approved"></i>
                        <span>Register Your Account</span>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-4">
                    <div class="account-item">
                        <i class="flaticon-cv"></i>
                        <span>Upload Your Resume</span>
                    </div>
                </div>

                <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                    <div class="account-item account-last">
                        <i class="flaticon-customer-service"></i>
                        <span>Apply for Dream Job</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <section class="category-area ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>Choose Your Desire Category</h2>
            </div>

            <div class="row">
                <div class="col-sm-3 col-lg-3 category-border">
                    <div class="category-item">
                        <i class="flaticon-settings"></i>
                        <a routerLink="/">Technical Support</a>
                    </div>
                </div>

                <div class="col-sm-3 col-lg-3 category-border">
                    <div class="category-item category-two">
                        <i class="flaticon-layers"></i>
                        <a routerLink="/">Business Development</a>
                    </div>
                </div>

                <div class="col-sm-3 col-lg-3 category-border">
                    <div class="category-item category-three">
                        <i class="flaticon-house"></i>
                        <a routerLink="/">Real Estate Business</a>
                    </div>
                </div>

                <div class="col-sm-3 col-lg-3 category-border">
                    <div class="category-item category-four">
                        <i class="flaticon-analysis"></i>
                        <a routerLink="/">Share Maeket Analysis</a>
                    </div>
                </div>

                <div class="col-sm-3 col-lg-3 category-border-three">
                    <div class="category-item category-five">
                        <i class="flaticon-sun"></i>
                        <a routerLink="/">Weather & Environment</a>
                    </div>
                </div>

                <div class="col-sm-3 col-lg-3 category-border-two">
                    <div class="category-item category-six">
                        <i class="flaticon-hand"></i>
                        <a routerLink="/">Finance & Banking Service</a>
                    </div>
                </div>

                <div class="col-sm-3 col-lg-3 category-border-two">
                    <div class="category-item category-seven">
                        <i class="flaticon-neural"></i>
                        <a routerLink="/">IT & Networing Sevices</a>
                    </div>
                </div>

                <div class="col-sm-3 col-lg-3 category-border-two">
                    <div class="category-item category-eight">
                        <i class="flaticon-dish"></i>
                        <a routerLink="/">Restaurant Services</a>
                    </div>
                </div>

                <div
                    class="col-sm-3 offset-sm-3 offset-lg-0 col-lg-3 category-border-two"
                >
                    <div class="category-item category-nine">
                        <i class="icofont-fire-burn"></i>
                        <a routerLink="/">Defence & Fire Service</a>
                    </div>
                </div>

                <div class="col-sm-3 col-lg-3">
                    <div class="category-item category-ten">
                        <i class="flaticon-truck"></i>
                        <a routerLink="/">Home Delivery Services</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="portal-area pb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="portal-item">
                        <div class="row">
                            <div class="col-lg-7">
                                <img
                                    src="../../../../assets/img/home-1/about1.jpg"
                                    alt="Portal"
                                />
                            </div>

                            <div class="col-lg-5">
                                <img
                                    src="../../../../assets/img/home-1/about2.jpg"
                                    alt="Portal"
                                />
                            </div>
                        </div>

                        <div class="portal-trusted">
                            <span>100% Trusted</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="portal-item portal-right">
                        <h2>Trusted & Popular Job Portal</h2>
                        <p>
                            Founded in 2016, our company has grown to become a
                            leading name in the Australian recruitment industry.
                            From our humble beginnings, we have built a
                            reputation for excellence,dedication and a deep
                            understanding of the sectors we serve across all of
                            Australia.Our success story is rooted in our
                            commitment to connecting the right talent with the
                            right opportunities, ensuring that businesses
                            thrive, and professionals reach their full
                            potential.We started our journey by recruiting for
                            Education and over the years, we have expanded our
                            reach across various sectors such as Healthcare, IT,
                            Engineering, Hospitality, Manufacturing, Trade and
                            Horticulture.
                        </p>
                        <div class="common-btn">
                            <a class="login-btn" routerLink="/post-a-job"
                                >Post a Job <i class="icofont-swoosh-right"></i
                            ></a>
                            <a class="sign-up-btn" routerLink="/create-account"
                                >Apply Now <i class="icofont-swoosh-right"></i
                            ></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <section class="job-area pb-70">
        <div class="container">
            <div class="section-title">
                <h2>Recent Jobs</h2>
            </div>

            <div class="sorting-menu">
                <ul>
                    <li class="filter active" data-filter="all">All</li>
                    <li class="filter" data-filter=".new">New</li>
                    <li class="filter" data-filter=".feature">Featured</li>
                    <li class="filter" data-filter=".casual">Casual</li>
                    <li class="filter" data-filter=".contractor">Contractor</li>
                    <li class="filter" data-filter=".fullTime">Full Time</li>
                    <li class="filter" data-filter=".partTime">Part Time</li>
                </ul>
            </div>

            <div id="container">
                <div class="row">
                    <div class="col-lg-6 mix all">
                        <div class="job-item">
                            <img
                                src="../../../../assets/img/home-1/icon.jpeg"
                                style="width: 70px"
                                alt="Job"
                            />

                            <div class="job-inner align-items-center">
                                <div class="job-inner-left">
                                    <h3>UI/UX Designer</h3>
                                    <a routerLink="/company-details"
                                        >Winbrans.com</a
                                    >
                                    <ul>
                                        <li>
                                            <i class="icofont-money-bag"></i>
                                            $20k - $25k
                                        </li>
                                        <li>
                                            <i class="icofont-location-pin"></i>
                                            Location 210-27 Quadra, Market
                                            Street, Victoria Canada
                                        </li>
                                    </ul>
                                </div>

                                <div class="job-inner-right">
                                    <ul>
                                        <li>
                                            <a routerLink="/create-account"
                                                >Apply</a
                                            >
                                        </li>
                                        <!-- <li><span>Full Time</span></li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 mix new feature">
                        <div class="job-item">
                            <img
                                src="../../../../assets/img/home-1/icon.jpeg"
                                style="width: 70px"
                                alt="Job"
                            />

                            <!-- <img src="assets/img/home-1/jobs/3.png" alt="Job"> -->

                            <div class="job-inner align-items-center">
                                <div class="job-inner-left">
                                    <h3>Senior Manager</h3>
                                    <a routerLink="/company-details"
                                        >Glovibo.com</a
                                    >
                                    <ul>
                                        <li>
                                            <i class="icofont-money-bag"></i>
                                            $20k - $25k
                                        </li>
                                        <li>
                                            <i class="icofont-location-pin"></i>
                                            Location 210-27 Quadra, Market
                                            Street, Victoria Canada
                                        </li>
                                    </ul>
                                </div>

                                <div class="job-inner-right">
                                    <ul>
                                        <li>
                                            <a routerLink="/create-account"
                                                >Apply</a
                                            >
                                        </li>
                                        <!-- <li><span>Intern</span></li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 mix feature casual">
                        <div class="job-item">
                            <img
                                src="../../../../assets/img/home-1/icon.jpeg"
                                style="width: 70px"
                                alt="Job"
                            />
                            <!-- <img src="assets/img/home-1/jobs/4.png" alt="Job"> -->

                            <div class="job-inner align-items-center">
                                <div class="job-inner-left">
                                    <h3>Product Designer</h3>
                                    <a routerLink="/company-details"
                                        >Bizotic.com</a
                                    >
                                    <ul>
                                        <li>
                                            <i class="icofont-money-bag"></i>
                                            $20k - $25k
                                        </li>
                                        <li>
                                            <i class="icofont-location-pin"></i>
                                            Location 210-27 Quadra, Market
                                            Street, Victoria Canada
                                        </li>
                                    </ul>
                                </div>

                                <div class="job-inner-right">
                                    <ul>
                                        <li>
                                            <a routerLink="/create-account"
                                                >Apply</a
                                            >
                                        </li>
                                        <!-- <li><span>Part Time</span></li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 mix casual contractor">
                        <div class="job-item">
                            <img
                                src="../../../../assets/img/home-1/icon.jpeg"
                                style="width: 70px"
                                alt="Job"
                            />
                            <!-- <img src="assets/img/home-1/jobs/5.png" alt="Job"> -->

                            <div class="job-inner align-items-center">
                                <div class="job-inner-left">
                                    <h3>Digital Marketer</h3>
                                    <a routerLink="/company-details"
                                        >Hotelzo.com</a
                                    >
                                    <ul>
                                        <li>
                                            <i class="icofont-money-bag"></i
                                            >$20k - $25k
                                        </li>
                                        <li>
                                            <i class="icofont-location-pin"></i
                                            >Location 210-27 Quadra, Market
                                            Street, Victoria Canada
                                        </li>
                                    </ul>
                                </div>

                                <div class="job-inner-right">
                                    <ul>
                                        <li>
                                            <a routerLink="/create-account"
                                                >Apply</a
                                            >
                                        </li>
                                        <!-- <li><span>Intern</span></li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 mix contractor fullTime">
                        <div class="job-item">
                            <img
                                src="../../../../assets/img/home-1/icon.jpeg"
                                style="width: 70px"
                                alt="Job"
                            />
                            <!-- <img src="assets/img/home-1/jobs/6.png" alt="Job"> -->

                            <div class="job-inner align-items-center">
                                <div class="job-inner-left">
                                    <h3>Sales Manager</h3>
                                    <a routerLink="/company-details"
                                        >Gozuto.com</a
                                    >
                                    <ul>
                                        <li>
                                            <i class="icofont-money-bag"></i
                                            >$20k - $25k
                                        </li>
                                        <li>
                                            <i class="icofont-location-pin"></i
                                            >Location 210-27 Quadra, Market
                                            Street, Victoria Canada
                                        </li>
                                    </ul>
                                </div>

                                <div class="job-inner-right">
                                    <ul>
                                        <li>
                                            <a routerLink="/create-account"
                                                >Apply</a
                                            >
                                        </li>
                                        <!-- <li><span>Part Time</span></li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 mix fullTime feature">
                        <div class="job-item">
                            <img
                                src="../../../../assets/img/home-1/icon.jpeg"
                                style="width: 70px"
                                alt="Job"
                            />
                            <!-- <img src="assets/img/home-1/jobs/7.png" alt="Job"> -->

                            <div class="job-inner align-items-center">
                                <div class="job-inner-left">
                                    <h3>Web Developer</h3>
                                    <a routerLink="/company-details"
                                        >Udiza.com</a
                                    >
                                    <ul>
                                        <li>
                                            <i class="icofont-money-bag"></i
                                            >$20k - $25k
                                        </li>
                                        <li>
                                            <i class="icofont-location-pin"></i
                                            >Location 210-27 Quadra, Market
                                            Street, Victoria Canada
                                        </li>
                                    </ul>
                                </div>

                                <div class="job-inner-right">
                                    <ul>
                                        <li>
                                            <a routerLink="/create-account"
                                                >Apply</a
                                            >
                                        </li>
                                        <!-- <li><span>Full Time</span></li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 mix partTime casual">
                        <div class="job-item">
                            <img
                                src="../../../../assets/img/home-1/icon.jpeg"
                                style="width: 70px"
                                alt="Job"
                            />
                            <!-- <img src="assets/img/home-1/jobs/8.png" alt="Job"> -->

                            <div class="job-inner align-items-center">
                                <div class="job-inner-left">
                                    <h3>SEO</h3>
                                    <a routerLink="/company-details"
                                        >Oqota.com</a
                                    >
                                    <ul>
                                        <li>
                                            <i class="icofont-money-bag"></i
                                            >$20k - $25k
                                        </li>
                                        <li>
                                            <i class="icofont-location-pin"></i
                                            >Location 210-27 Quadra, Market
                                            Street, Victoria Canada
                                        </li>
                                    </ul>
                                </div>

                                <div class="job-inner-right">
                                    <ul>
                                        <li>
                                            <a routerLink="/create-account"
                                                >Apply</a
                                            >
                                        </li>
                                        <!-- <li><span>Part Time</span></li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="counter-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-6 col-sm-3 col-lg-3">
                    <div class="counter-item">
                        <i class="flaticon-employee"></i>
                        <h3><span class="counter">14</span>k+</h3>
                        <p>Job Available</p>
                    </div>
                </div>

                <div class="col-6 col-sm-3 col-lg-3">
                    <div class="counter-item">
                        <i class="flaticon-curriculum"></i>
                        <h3><span class="counter">18</span>k+</h3>
                        <p>CV Submitted</p>
                    </div>
                </div>

                <div class="col-6 col-sm-3 col-lg-3">
                    <div class="counter-item">
                        <i class="flaticon-enterprise"></i>
                        <h3><span class="counter">9</span>k+</h3>
                        <p>Companies</p>
                    </div>
                </div>

                <div class="col-6 col-sm-3 col-lg-3">
                    <div class="counter-item">
                        <i
                            class="flaticon-businessman-paper-of-the-application-for-a-job"
                        ></i>
                        <h3><span class="counter">35</span>+</h3>
                        <p>Appointed to Job</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="popular-area pt-100 pb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="popular-item">
                        <div class="row align-items-center">
                            <div class="col-lg-7">
                                <img
                                    src="../../../../assets/img/home-1/about4.jpg"
                                    alt="Popular"
                                />
                            </div>

                            <div class="col-lg-5">
                                <div class="practice-inner">
                                    <img
                                        src="../../../../assets/img/home-1/about6.jpg"
                                        height="340px"
                                        alt="Popular"
                                    />
                                    <img
                                        src="../../../../assets/img/home-1/about3.jpg"
                                        alt="Popular"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="popular-item popular-right">
                        <div class="section-title text-start">
                            <h2>Why We are Most Popular</h2>
                        </div>
                        <p>
                            We have successfully extended our services to Gulf
                            countries and Singapore, and we have been able to
                            meet the unique needs of our clients there with
                            great success. This has marked as a significant
                            milestone in our journey as we serve clients and
                            candidates on a global scale. With our commitment to
                            excellence and a vast database, we are fully
                            equipped to meet the diverse needs of industries
                            worldwide.
                        </p>

                        <div class="row popular-wrap">
                            <div class="col-sm-6 col-lg-6">
                                <ul>
                                    <li>
                                        <i class="fa fa-user"></i
                                        ><span>CLIENT - CENTRIC APPROACH</span>
                                    </li>
                                    <li>
                                        <i class="flaticon-no-money"></i>
                                        EXPERTISE
                                    </li>
                                </ul>
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <ul>
                                    <li>
                                        <i class="flaticon-enterprise"></i
                                        >INTEGRITY
                                    </li>
                                    <li>
                                        <i class="flaticon-employee"></i>
                                        QUALITY
                                    </li>
                                </ul>
                            </div>

                            <div class="col-sm-6 col-lg-6 mt-3">
                                <ul>
                                    <li>
                                        <i class="flaticon-approved"></i
                                        ><span>INNOVATION</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-sm-6 col-lg-6 mt-3">
                                <ul>
                                    <li>
                                        <i class="fa fa-check"></i
                                        ><span>SUSTAINABILITY</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <section class="companies-area ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>Popular Companies</h2>
            </div>

            <div class="companies-slider owl-theme owl-carousel">
                <div class="companies-item">
                    <img
                        src="assets/img/home-1/companies/1.png"
                        alt="Companies"
                    />
                    <h3><a routerLink="/company-details">Winbrans.com</a></h3>
                    <p>
                        <i class="icofont-location-pin"></i> Quadra, Street,
                        Canada
                    </p>
                    <a class="companies-btn" routerLink="/create-account"
                        >Hiring</a
                    >
                </div>

                <div class="companies-item">
                    <img
                        src="assets/img/home-1/companies/2.png"
                        alt="Companies"
                    />
                    <h3><a routerLink="/company-details">Infiniza.com</a></h3>
                    <p>
                        <i class="icofont-location-pin"></i> North Street,
                        California
                    </p>
                    <a class="companies-btn" routerLink="/create-account"
                        >Hiring</a
                    >
                </div>

                <div class="companies-item">
                    <img
                        src="assets/img/home-1/companies/3.png"
                        alt="Companies"
                    />
                    <h3><a routerLink="/company-details">Glovibo.com</a></h3>
                    <p><i class="icofont-location-pin"></i> Barming Road, UK</p>
                    <a class="companies-btn" routerLink="/create-account"
                        >Hiring</a
                    >
                </div>

                <div class="companies-item">
                    <img
                        src="assets/img/home-1/companies/4.png"
                        alt="Companies"
                    />
                    <h3><a routerLink="/company-details">Bizotic.com</a></h3>
                    <p>
                        <i class="icofont-location-pin"></i> Washington, New
                        York
                    </p>
                    <a class="companies-btn" routerLink="/create-account"
                        >Hiring</a
                    >
                </div>

                <div class="companies-item">
                    <img
                        src="assets/img/home-1/companies/1.png"
                        alt="Companies"
                    />
                    <h3><a routerLink="/company-details">Winbrans.com</a></h3>
                    <p>
                        <i class="icofont-location-pin"></i> Quadra, Street,
                        Canada
                    </p>
                    <a class="companies-btn" routerLink="/create-account"
                        >Hiring</a
                    >
                </div>

                <div class="companies-item">
                    <img
                        src="assets/img/home-1/companies/2.png"
                        alt="Companies"
                    />
                    <h3><a routerLink="/company-details">Infiniza.com</a></h3>
                    <p>
                        <i class="icofont-location-pin"></i> North Street,
                        California
                    </p>
                    <a class="companies-btn" routerLink="/create-account"
                        >Hiring</a
                    >
                </div>

                <div class="companies-item">
                    <img
                        src="assets/img/home-1/companies/3.png"
                        alt="Companies"
                    />
                    <h3><a routerLink="/company-details">Glovibo.com</a></h3>
                    <p><i class="icofont-location-pin"></i> Barming Road, UK</p>
                    <a class="companies-btn" routerLink="/create-account"
                        >Hiring</a
                    >
                </div>

                <div class="companies-item">
                    <img
                        src="assets/img/home-1/companies/4.png"
                        alt="Companies"
                    />
                    <h3><a routerLink="/company-details">Bizotic.com</a></h3>
                    <p>
                        <i class="icofont-location-pin"></i> Washington, New
                        York
                    </p>
                    <a class="companies-btn" routerLink="/create-account"
                        >Hiring</a
                    >
                </div>

                <div class="companies-item">
                    <img
                        src="assets/img/home-1/companies/1.png"
                        alt="Companies"
                    />
                    <h3><a routerLink="/company-details">Winbrans.com</a></h3>
                    <p>
                        <i class="icofont-location-pin"></i> Quadra, Street,
                        Canada
                    </p>
                    <a class="companies-btn" routerLink="/create-account"
                        >Hiring</a
                    >
                </div>

                <div class="companies-item">
                    <img
                        src="assets/img/home-1/companies/2.png"
                        alt="Companies"
                    />
                    <h3><a routerLink="/company-details">Infiniza.com</a></h3>
                    <p>
                        <i class="icofont-location-pin"></i> North Street,
                        California
                    </p>
                    <a class="companies-btn" routerLink="/create-account"
                        >Hiring</a
                    >
                </div>

                <div class="companies-item">
                    <img
                        src="assets/img/home-1/companies/3.png"
                        alt="Companies"
                    />
                    <h3><a routerLink="/company-details">Glovibo.com</a></h3>
                    <p><i class="icofont-location-pin"></i> Barming Road, UK</p>
                    <a class="companies-btn" routerLink="/create-account"
                        >Hiring</a
                    >
                </div>

                <div class="companies-item">
                    <img
                        src="assets/img/home-1/companies/4.png"
                        alt="Companies"
                    />
                    <h3><a routerLink="/company-details">Bizotic.com</a></h3>
                    <p>
                        <i class="icofont-location-pin"></i> Washington, New
                        York
                    </p>
                    <a class="companies-btn" routerLink="/create-account"
                        >Hiring</a
                    >
                </div>
            </div>
        </div>
    </section>

    <!-- <section class="profile-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Featured Profile</h2>
        </div>

        <div class="profile-slider owl-theme owl-carousel">
            <div class="profile-item">
                <img src="assets/img/home-1/profile/1.jpg" alt="Profile">
                <div class="profile-inner">
                    <h3>Jerry Hudson</h3>
                    <span>Business Consultant</span>
                    <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                    <div class="profile-heart">
                        <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="profile-item">
                <img src="assets/img/home-1/profile/2.jpg" alt="Profile">
                <div class="profile-inner">
                    <h3>Jac Jacson</h3>
                    <span>Web Consultant</span>
                    <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                    <div class="profile-heart">
                        <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="profile-item">
                <img src="assets/img/home-1/profile/3.jpg" alt="Profile">
                <div class="profile-inner">
                    <h3>Tom Potter</h3>
                    <span>UX/UI Consultant</span>
                    <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                    <div class="profile-heart">
                        <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                    </div>
                </div>
            </div>
            
            <div class="profile-item">
                <img src="assets/img/home-1/profile/4.jpg" alt="Profile">
                <div class="profile-inner">
                    <h3>Shane Mac</h3>
                    <span>SEO Consultant </span>
                    <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                    <div class="profile-heart">
                        <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="profile-item">
                <img src="assets/img/home-1/profile/5.jpg" alt="Profile">
                <div class="profile-inner">
                    <h3>Jerry Hudson</h3>
                    <span>Business Consultant</span>
                    <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                    <div class="profile-heart">
                        <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="profile-item">
                <img src="assets/img/home-1/profile/6.jpg" alt="Profile">
                <div class="profile-inner">
                    <h3>Jac Jacson</h3>
                    <span>Web Consultant</span>
                    <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                    <div class="profile-heart">
                        <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="profile-item">
                <img src="assets/img/home-1/profile/7.jpg" alt="Profile">
                <div class="profile-inner">
                    <h3>Tom Potter</h3>
                    <span>UX/UI Consultant</span>
                    <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                    <div class="profile-heart">
                        <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="profile-item">
                <img src="assets/img/home-1/profile/8.jpg" alt="Profile">
                <div class="profile-inner">
                    <h3>Shane Mac</h3>
                    <span>SEO Consultant </span>
                    <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                    <div class="profile-heart">
                        <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="profile-item">
                <img src="assets/img/home-1/profile/1.jpg" alt="Profile">
                <div class="profile-inner">
                    <h3>Jerry Hudson</h3>
                    <span>Business Consultant</span>
                    <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                    <div class="profile-heart">
                        <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="profile-item">
                <img src="assets/img/home-1/profile/2.jpg" alt="Profile">
                <div class="profile-inner">
                    <h3>Jac Jacson</h3>
                    <span>Web Consultant</span>
                    <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                    <div class="profile-heart">
                        <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="profile-item">
                <img src="assets/img/home-1/profile/3.jpg" alt="Profile">
                <div class="profile-inner">
                    <h3>Tom Potter</h3>
                    <span>UX/UI Consultant</span>
                    <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                    <div class="profile-heart">
                        <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                    </div>
                </div>
            </div>

            <div class="profile-item">
                <img src="assets/img/home-1/profile/4.jpg" alt="Profile">
                <div class="profile-inner">
                    <h3>Shane Mac</h3>
                    <span>SEO Consultant </span>
                    <a routerLink="/candidate-details">View Profile<i class="icofont-swoosh-right"></i></a>
                    <div class="profile-heart">
                        <a routerLink="/candidate-details"><i class="icofont-heart-alt"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

    <!-- <div class="app-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="app-item app-left">
                        <img src="assets/img/home-1/6.png" alt="Mobile" />
                        <img src="assets/img/home-1/7.png" alt="Mobile" />
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="app-item">
                        <div class="section-title text-start">
                            <h2>Download The Glabe Mobile App</h2>
                        </div>
                        <p>
                            Quis ipsum suspendisse ultrices gravida. Risus
                            commodo viverra maecenas accumsan lacus vel
                            facilisis. Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit, sed do eiusmod.
                        </p>
                        <div class="app-btn">
                            <a class="app-btn-one" href="#">
                                <i class="flaticon-apple"></i>
                                <span>Download on the</span>
                                <p>App Store</p>
                            </a>
                            <a class="app-btn-two" href="#">
                                <i class="flaticon-playstore"></i>
                                <span>ANDROID APP ON</span>
                                <p>Google Play</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="container latest-news">
        <div class="section-title">
            <h2>LATEST NEWS</h2>
        </div>
        <!-- <h2 class="text-center text-success">LATEST NEWS</h2> -->
        <div class="row mt-4">
            <div class="col-md-3">
                <div class="card">
                    <video width="100%" height="auto" controls>
                        <source
                            src="assets/img/home-1/magnus1.mp4"
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                    <div class="card-body text-center">
                        <h6 class="card-title">Magnus Recruitment...</h6>
                        <p class="card-text">Weekly Job Newsletter</p>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="card">
                    <video width="100%" height="auto" controls>
                        <source
                            src="assets/img/home-1/magnus2.mp4"
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                    <div class="card-body text-center">
                        <h6 class="card-title">Magnus Recruitment...</h6>
                        <p class="card-text">Weekly Job Newsletter</p>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="card">
                    <video width="100%" height="auto" controls>
                        <source
                            src="assets/img/home-1/magnus3.mp4"
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                    <div class="card-body text-center">
                        <h6 class="card-title">Magnus Recruitment...</h6>
                        <p class="card-text">Weekly Job Newsletter</p>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="card">
                    <video width="100%" height="auto" controls>
                        <source
                            src="assets/img/home-1/magnus4.mp4"
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                    <div class="card-body text-center">
                        <h6 class="card-title">Magnus Recruitment...</h6>
                        <p class="card-text">Weekly Job Newsletter</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="container mt-5">
    <h2 class="text-center text-success">LATEST NEWS</h2>
    <div class="row mt-4">
      <div class="col-md-3">
        <div class="card">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              class="embed-responsive-item"
              src="../../../../assets/img/home-1/magnus1.mp4"
              allowfullscreen
            ></iframe>
          </div>
          <div class="card-body text-center">
            <h6 class="card-title">Magnus Recruitment...</h6>
            <p class="card-text">Weekly Job Newsletter</p>
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="card">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              class="embed-responsive-item"
              src="../../../../assets/img/home-1/magnus2.mp4"
              allowfullscreen
            ></iframe>
          </div>
          <div class="card-body text-center">
            <h6 class="card-title">Magnus Recruitment...</h6>
            <p class="card-text">Weekly Job Newsletter</p>
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="card">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              class="embed-responsive-item"
              src="../../../../assets/img/home-1/magnus3.mp4"
              allowfullscreen
            ></iframe>
          </div>
          <div class="card-body text-center">
            <h6 class="card-title">Magnus Recruitment...</h6>
            <p class="card-text">Weekly Job Newsletter</p>
          </div>
        </div>
      </div>

      <div class="col-md-3">
        <div class="card">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              class="embed-responsive-item"
              src="../../../../assets/img/home-1/magnus4.mp4"
              allowfullscreen
            ></iframe>
          </div>
          <div class="card-body text-center">
            <h6 class="card-title">Magnus Recruitment...</h6>
            <p class="card-text">Weekly Job Newsletter</p>
          </div>
        </div>
      </div>
    </div>
  </div> -->

    <section class="blog-area pt-100 pb-170">
        <div class="container">
            <div class="section-title">
                <h2>Our Latest Blogs</h2>
            </div>

            <div class="row">
                <div class="col-sm-6 col-lg-4">
                    <div class="blog-item wow fadeInUp" data-wow-delay=".3s">
                        <div class="blog-top">
                            <a routerLink="/blog-details"
                                ><img
                                    src="assets/img/home-1/blog/1.jpg"
                                    alt="Blog"
                            /></a>
                            <span>21 May, 2024</span>
                        </div>

                        <div class="blog-bottom">
                            <h3>
                                <a routerLink="/blog-details"
                                    >The next genaration IT will change the
                                    world</a
                                >
                            </h3>
                            <ul>
                                <li>
                                    <img
                                        src="assets/img/home-1/blog/1.png"
                                        alt="Blog"
                                    />
                                    Aikin Ward
                                </li>
                                <li>
                                    <a routerLink="/blog-details"
                                        >Read More<i
                                            class="icofont-simple-right"
                                        ></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-4">
                    <div class="blog-item wow fadeInUp" data-wow-delay=".4s">
                        <div class="blog-top">
                            <a routerLink="/blog-details"
                                ><img
                                    src="assets/img/home-1/blog/2.jpg"
                                    alt="Blog"
                            /></a>
                            <span>22 May, 2024</span>
                        </div>

                        <div class="blog-bottom">
                            <h3>
                                <a routerLink="/blog-details"
                                    >It is the most important sector in the
                                    world</a
                                >
                            </h3>
                            <ul>
                                <li>
                                    <img
                                        src="assets/img/home-1/blog/1.png"
                                        alt="Blog"
                                    />Aikin Ward
                                </li>
                                <li>
                                    <a routerLink="/blog-details"
                                        >Read More<i
                                            class="icofont-simple-right"
                                        ></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                    <div class="blog-item wow fadeInUp" data-wow-delay=".5s">
                        <div class="blog-top">
                            <a routerLink="/blog-details"
                                ><img
                                    src="assets/img/home-1/blog/3.jpg"
                                    alt="Blog"
                            /></a>
                            <span>23 May, 2024</span>
                        </div>

                        <div class="blog-bottom">
                            <h3>
                                <a routerLink="/blog-details"
                                    >Nowadays IT is being most popular</a
                                >
                            </h3>
                            <ul>
                                <li>
                                    <img
                                        src="assets/img/home-1/blog/1.png"
                                        alt="Blog"
                                    />
                                    Aikin Ward
                                </li>
                                <li>
                                    <a routerLink="/blog-details">
                                        Read More<i
                                            class="icofont-simple-right"
                                        ></i
                                    ></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
