<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Blog</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Blog</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-area pt-100 pb-170">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow fadeInUp" data-wow-delay=".3s">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-1/blog/1.jpg" alt="Blog"></a>
                        <span>21 May, 2024</span>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">The next genaration IT will change the world</a></h3>
                        <ul>
                            <li><img src="assets/img/home-1/blog/1.png" alt="Blog">Aikin Ward</li>
                            <li><a routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow fadeInUp" data-wow-delay=".4s">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-1/blog/2.jpg" alt="Blog"></a>
                        <span>22 May, 2024</span>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">IT is the most important sector in the world</a></h3>
                        <ul>
                            <li><img src="assets/img/home-1/blog/1.png" alt="Blog">Daniel Henry</li>
                            <li><a routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow fadeInUp" data-wow-delay=".5s">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-1/blog/3.jpg" alt="Blog"></a>
                        <span>23 May, 2024</span>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Nowadays IT is being most popular</a></h3>
                        <ul>
                            <li><img src="assets/img/home-1/blog/1.png" alt="Blog">Lucas Mason</li>
                            <li><a routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow fadeInUp" data-wow-delay=".3s">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-1/blog/4.jpg" alt="Blog"></a>
                        <span>26 May, 2024</span>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">IT job is an smart profession in the world</a></h3>
                        <ul>
                            <li><img src="assets/img/home-1/blog/1.png" alt="Blog">William James</li>
                            <li><a routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow fadeInUp" data-wow-delay=".4s">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-1/blog/5.jpg" alt="Blog"></a>
                        <span>27 May, 2024</span>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Economy growth is being increased by IT sectors</a></h3>
                        <ul>
                            <li><img src="assets/img/home-1/blog/1.png" alt="Blog">Liam Noah</li>
                            <li><a routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item wow fadeInUp" data-wow-delay=".5s">
                    <div class="blog-top">
                        <a routerLink="/blog-details"><img src="assets/img/home-1/blog/6.jpg" alt="Blog"></a>
                        <span>28 May, 2024</span>
                    </div>

                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-details">Every country is giving priority in IT sector</a></h3>
                        <ul>
                            <li><img src="assets/img/home-1/blog/1.png" alt="Blog">Tom Potter</li>
                            <li><a routerLink="/blog-details">Read More<i class="icofont-simple-right"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>