export const environment = {
    production: false,
    title: ' Staging Environment',
    baseUrl: "https://www.forestumbrella.co.uk/api/v1",
    imageUrl: "https://www.forestumbrella.co.uk",
    file_url:""
};



