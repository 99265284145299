import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {
  videos = [
    { title: 'Magnus Recruitment...', url: '../../../../assets/img/home-1/magnus.mp4', description: 'Weekly Job Newsletter' },
    { title: 'Magnus Recruitment...', url: '../../../../assets/img/home-1/magnus.mp4', description: 'Weekly Job Newsletter' },
    { title: 'Magnus Recruitment...', url: '../../../../assets/img/home-1/magnus.mp4', description: 'Weekly Job Newsletter' },
    { title: 'Magnus Recruitment...', url: '../../../../assets/img/home-1/magnus.mp4', description: 'Weekly Job Newsletter' }
  ];
  constructor() { }

  ngOnInit(): void {
    localStorage.removeItem('questions-id')
  }

}
