import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/store/auth/auth.service";
import { UserRole } from "src/app/shared/enums/roles";


@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
    loginAuthO = this.authService.userO;
    registerUser = this.authService.userO;
    private loginSub: Subscription;
    private registerSub: Subscription;
    user: boolean = false;
    updateLoginUser: boolean;

    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
       

    ) {}



    ngOnInit(): void {
    }

    ngAfterViewInit(){
        setTimeout(() => {
            this.loginSub = this.loginAuthO.subscribe((data) => {
                console.log('Loginnnnnnnnnn dataaaa',data)
                if(!data) return
                if(data?.isData === true){
                    this.user = true
                }
            });
          },0);
    }


    handleLogout() {
        this.user = false;
        localStorage.removeItem("token");
        localStorage.removeItem("user-data");
        localStorage.removeItem("qualification");
        localStorage.removeItem("certificates")
        this.authService.setNullUser();
        this.router.navigate(["/management-module/login"]);
    }

    isUserLoggedIn() {}

    ngOnDestroy() {
        this.loginSub.unsubscribe();
        this.registerSub.unsubscribe();
    }
}
