export namespace AuthActions {
    export class Register {
        static readonly type = "[Auth] Register";
        constructor(public payload: any) {}
    }


    export class Login {
        static readonly type = "[Auth] Login";
        isData: any;
        constructor(public payload: any) {}
    }

    export class GetUser {
        static readonly type = "[Auth] Get User Info";
        constructor(public payload: any) {}
    }

    export class UpdateUser {
        static readonly type = "[Auth] Update User Info";
        constructor(public payload: any) {}
    }
}
