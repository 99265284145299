<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Log In</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Log In</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="login-area pt-100">
    <div class="container">
        <h2>Login Your Account</h2>

        <div class="login-wrap">
            <div class="row">
                <div class="col-sm-6 col-lg-6">
                    <div class="jobseeker-item">
                        <div class="jobseeker-icon">
                            <i class="flaticon-job-search"></i>
                        </div>
                        
                        <div class="jobseeker-inner">
                            <span>Candidate</span>
                            <h3>Login as a Candidate</h3>
                        </div>

                        <a routerLink="/login">Get Started<i class="icofont-arrow-right"></i></a>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <div class="jobseeker-item">
                        <div class="jobseeker-icon">
                            <i class="flaticon-recruitment"></i>
                        </div>

                        <div class="jobseeker-inner">
                            <span>Employer</span>
                            <h3>Login as a Employer</h3>
                        </div>

                        <a routerLink="/login">Get Started<i class="icofont-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>

        <form [formGroup]="signIn">
            <div class="form-group">
                <input type="email" class="form-control" placeholder="Email" formControlName="email">
                <!-- <app-validation-error [control]="signIn.get('email')" ></app-validation-error> -->
            </div>

            <div class="form-group">
                <input type="password" class="form-control" placeholder="Password" formControlName="password">
                <!-- <app-validation-error [control]="signIn.get('password')"></app-validation-error> -->
            </div>
        </form>

        <div class="login-sign-in">
            <a routerLink="/forgot-password">Forgot Password?</a>

            <ul>
                <li>Don’t Have Account ?</li>
                <li><a routerLink="/create-account">Sign Up Here</a></li>
            </ul>

            <div class="text-center">
                <button type="submit" (click)="submit()" class="btn login-btn">Sign In</button>
            </div>
        </div>

        <div class="login-social">
            <a href="#"><i class="icofont-facebook"></i> Login With Facebook</a>
            <a class="login-google" href="#"><i class="icofont-google-plus"></i> Login With Google</a>
        </div>
    </div>
</div>