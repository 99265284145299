import { Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
    signIn: FormGroup | any;
    // userO = this._UserService.userO;

    constructor(private readonly fb: FormBuilder) {}

    ngOnInit(): void {
        this.signInForm();

        // this.userRef = this.userO.subscribe((x) => {
        //     console.log("SignIn Component-----", x);
        //     if (x) {

        //     }
        //   });
    }

    signInForm() {
        this.signIn = this.fb.group({
            email: new FormControl("", [Validators.required]),
            password: new FormControl("", [Validators.required]),
        });
    }

    submit() {
        const formValue = this.signIn.value;
        console.log(formValue);

        // this._UserService.login({
        //     userName: email,
        //     email: email,
        //     password: password,
        //   });
    }
}
