import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
forgotPassword : FormGroup | any

constructor(private readonly fb:FormBuilder){
}

ngOnInit(): void {
  this.forgotPasswordForm()
}

forgotPasswordForm(){
  this.forgotPassword = this.fb.group({
    email : new FormControl ('',[Validators.required]),
  })
}

submit(){
  const formValue = this.forgotPassword.value;
  console.log(formValue)
}

}
