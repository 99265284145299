<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text">
                    <h2>Favourite Job</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Favourite Job</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="job-area job-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="job-item">
                    <img src="assets/img/home-1/jobs/1.png" alt="Job">

                    <div class="job-inner align-items-center">
                        <div class="job-inner-left">
                            <h3>UI/UX Designer</h3>
                            <a routerLink="/company-details">Winbrans.com</a>
                            <ul>
                                <li><i class="icofont-money-bag"></i> $20k - $25k</li>
                                <li><i class="icofont-location-pin"></i> Location 210-27 Quadra, Market Street, Victoria Canada</li>
                            </ul>
                        </div>

                        <div class="job-inner-right">
                            <ul>
                                <li><a routerLink="/create-account">Apply</a></li>
                                <li><span>Full Time</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="job-item">
                    <img src="assets/img/home-1/jobs/2.png" alt="Job">

                    <div class="job-inner align-items-center">
                        <div class="job-inner-left">
                            <h3>Android Developer</h3>
                            <a routerLink="/company-details">Infiniza.com</a>
                            <ul>
                                <li><i class="icofont-money-bag"></i> $20k - $25k</li>
                                <li><i class="icofont-location-pin"></i> Location 210-27 Quadra, Market Street, Victoria Canada</li>
                            </ul>
                        </div>

                        <div class="job-inner-right">
                            <ul>
                                <li><a routerLink="/create-account">Apply</a></li>
                                <li><span>Part Time</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6"> 
                <div class="job-item">
                    <img src="assets/img/home-1/jobs/3.png" alt="Job">

                    <div class="job-inner align-items-center">
                        <div class="job-inner-left">
                            <h3>Senior Manager</h3>
                            <a routerLink="/company-details">Glovibo.com</a>
                            <ul>
                                <li><i class="icofont-money-bag"></i> $20k - $25k</li>
                                <li><i class="icofont-location-pin"></i> Location 210-27 Quadra, Market Street, Victoria Canada</li>
                            </ul>
                        </div>

                        <div class="job-inner-right">
                            <ul>
                                <li><a routerLink="/create-account">Apply</a></li>
                                <li><span>Intern</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="job-item ">
                    <img src="assets/img/home-1/jobs/4.png" alt="Job">

                    <div class="job-inner align-items-center">
                        <div class="job-inner-left">
                            <h3>Product Designer</h3>
                            <a routerLink="/company-details">Bizotic.com</a>
                            <ul>
                                <li><i class="icofont-money-bag"></i> $20k - $25k</li>
                                <li><i class="icofont-location-pin"></i> Location 210-27 Quadra, Market Street, Victoria Canada</li>
                            </ul>
                        </div>

                        <div class="job-inner-right">
                            <ul>
                                <li><a routerLink="/create-account">Apply</a></li>
                                <li><span>Part Time</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>