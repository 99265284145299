import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CandidateEmployerListService {


  constructor(private readonly httpClient: HttpClient) { }

 
  //get all candidate list
  getAllCandidateList(
    currentPage: number,
    totalItemPerPage: number,
    firstName: string = "",
    middleName: string = "",
    lastName: string = "",
    email: string = "",
    phoneNumber: string = ""
  ) {
    return this.httpClient.get(
      `${environment.baseUrl}/candidate/get-all-candidates/${currentPage}/${totalItemPerPage}?firstName=${firstName}&middleName=${middleName}&lastName=${lastName}&email=${email}&phoneNumber=${phoneNumber}`
    );
  }


  //get all employers list
  getAllEmployerList(
    currentPage: number,
    totalItemPerPage: number,
    businessName:string = "",
    collegeName:string = ""
  ){
    return this.httpClient.get(
         `${environment.baseUrl}/employer/get-all-employers/${currentPage}/${totalItemPerPage}?businessName=${businessName}&collegeName=${collegeName}`
  );
  }


  //get specific candidate by id
  getSpecificCandidateById(id:any) {
   return this.httpClient.get(`${environment.baseUrl}/candidate/get-specific-candidate/${id}`)
  }

  //assign email to employer
  assignEmailToEmployer(id: any, email:any) {
    return this.httpClient.patch(`${environment.baseUrl}/candidate/update-candidate-key?candidateId=${id}&email=${email}`,{})
  }

  //get all candidates assigned to employer
  getAllCandidatesAssignedToEmployer(currentPage: number,
    totalItemPerPage: number,email: string, firstName: string, lastName: string, category: string) {
    return this.httpClient.get(`${environment.baseUrl}/candidate/get-candidates-for-employee/${currentPage}/${totalItemPerPage}?email=${email}&firstName=${firstName}&lastName=${lastName}&category=${category}`)
  }


  
}
