import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class JobCreateService {
    constructor(private readonly httpClient: HttpClient) {}

    //job category list
    getJobCategoryList(){
        return this.httpClient.get(`${environment.baseUrl}/candidate-job-details-management/categories-with-job-count`)
    }

    //create job
    createJob(payload: any) {
        return this.httpClient.post(
            `${environment.baseUrl}/candidate-job-details-management/add-job-info`,
            payload
        );
    }

  
    //filter job listing
    getJobListing(
        currentPage: number,
        totalItemPerPage: number,
        jobCategories: any[] = [],
        jobTitle: string = "",
        jobLocation: string = "",
        jobExperience: string = "",
        salaryRange: string = "",
        jobType: string = ""
    ) {
        // Convert jobCategories array to a JSON string and encode it
        const encodedCategories = encodeURIComponent(JSON.stringify(jobCategories));
        // Encode salaryRange to ensure it is properly formatted in the URL
        const encodedSalaryRange = encodeURIComponent(salaryRange);
        // Build the URL with all parameters encoded
        const url = `${environment.baseUrl}/candidate-job-details-management/get-all-job-info/${currentPage}/${totalItemPerPage}/?jobCategories=${encodedCategories}&jobTitle=${encodeURIComponent(jobTitle)}&jobLocation=${encodeURIComponent(jobLocation)}&jobExperience=${encodeURIComponent(jobExperience)}&salaryRange=${encodedSalaryRange}&jobType=${encodeURIComponent(jobType)}`;
        return this.httpClient.get(url);
    }
}
