import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class LocalStorageService {
    private jobId: string;

    constructor() {}

    //save user token
    userToken(payLoad: any) {
        localStorage.setItem("token", payLoad);
    }

    //get user token
    getUserToken(): string | null {
        return localStorage.getItem("token");
    }

    //save user login data
    setUserData(payload: any) {
        const jsonData = JSON.stringify(payload);
        localStorage.setItem("user-data", jsonData);
    }
    //get user login data
    getUserData() {
        return JSON.parse(localStorage.getItem("user-data")) || null;
    }
}
