<footer>
    <div class="container">
        <div class="subscribe-area">
            <div class="section-title">
                <h2>Subscribe Newsletter</h2>
            </div>

            <!-- <div class="subscribe-shape">
                <img src="assets/img/shape/1.png" alt="Shape">
                <img src="assets/img/shape/1.png" alt="Shape">
                <img src="assets/img/shape/1.png" alt="Shape">
                <img src="assets/img/shape/1.png" alt="Shape">
                <img src="assets/img/shape/2.png" alt="Shape">
            </div> -->

            <div class="subscribe-item">
                <form class="newsletter-form">
                    <input type="email" class="form-control" placeholder="Enter Your Email" name="EMAIL" required autocomplete="off">
                    <button class="btn subscribe-btn" type="submit">Subscribe</button>
                </form>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="../../../../assets/img/magnus-footer.png" class="footer_logo" alt="Logo"></a>
                        <p>Australia’s Leading Recruitment Company.</p>
                        <ul>
                            <!-- <li><a href="#" target="_blank"><i class="icofont-youtube-play"></i></a></li> -->
                            <li><a href="https://www.facebook.com/magnusrecruitmentaus/" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.linkedin.com/in/magnus-recruitment-solutions-13895b198/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <!-- <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-category">
                        <h3>Category</h3>

                        <ul>
                            <li><a routerLink="/"><i class="icofont-simple-right"></i> Development</a></li>
                            <li><a routerLink="/"><i class="icofont-simple-right"></i> Business</a></li>
                            <li><a routerLink="/"><i class="icofont-simple-right"></i> Tech & IT</a></li>
                            <li><a routerLink="/"><i class="icofont-simple-right"></i> Finance</a></li>
                            <li><a routerLink="/"><i class="icofont-simple-right"></i> Networking</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-category">
                        <h3>Quick Links</h3>

                        <ul>
                            <li><a routerLink="/"><i class="icofont-simple-right"></i> Home</a></li>
                            <li><a routerLink="/about"><i class="icofont-simple-right"></i> About Us</a></li>
                            <li><a routerLink="/blog"><i class="icofont-simple-right"></i> Blogs</a></li>
                            <li><a routerLink="/company-list"><i class="icofont-simple-right"></i> Companies</a></li>
                            <li><a routerLink="/"><i class="icofont-simple-right"></i> Testimonials</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-find">
                        <h3>Find Us</h3>

                        <ul>
                            <li><i class="icofont-ui-call"></i>0483 848 525</li>
                            <li><i class="icofont-ui-call"></i>0480 199 145</li>
                            <li><i class="icofont-ui-call"></i>1800 960 992</li>
                            <li><i class="icofont-ui-email"></i> 
                                info&#64;magnusrecruitment.com.au
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row">
                <div class="col-lg-6">
                    <div class="copyright-item">
                        <a href="https://www.stechs.com.au/" target="_blank">
                            <p class="text-white">&#64;2024 Magnus All Rights Reserved. Design Support: S-Tech Solution</p>
                        </a>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="copyright-item copyright-right">
                        <ul>
                            <li><a routerLink="/terms-and-conditions" target="_blank">Terms & Conditions</a></li>
                            <li><span>-</span></li>
                            <li><a routerLink="/privacy-policy" target="_blank">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>